.SectionGoals {
  padding-bottom: 0;

  picture {
    vertical-align: top;
    margin: 0 auto;
    display: block;

    img {
      vertical-align: top;
      margin: 0 auto;
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 55px;

    .container {
      position: relative;
    }

    &__content {
      display: flex;

      p {
        max-width: 390px;
      }

      picture {
        position: absolute;
        bottom: -55px;
        right: 0;
        max-width: calc(100% - 390px);

        @media (min-width: 1420px) {
          right: -50px;
        }
      }
    }
  }
}

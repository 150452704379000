* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-base;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.7;
  background-color: #f8f8f8;
  color: $text-color;
  overflow-x: hidden;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

p {
  margin: 5px 0 15px;
}

ol,
ul {
  padding: 0 0 0 35px;

  li {
    padding-bottom: 15px;
  }
}

a,
button {
  transition: background-color .2s, color .2s;
}

input,
textarea,
select,
button {
  font-family: $font-base;
}

h2,
h3,
.section-heading,
.heading {
  font-family: $font-montserrat;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 28px;
  line-height: 1.3;
  color: #22373c;
  position: relative;

  @media (min-width: 768px) {
    font-size: 42px;
    margin-bottom: 20px;
  }
}

.section-heading {
  display: inline-block;
  overflow: hidden;
  padding-bottom: 35px;
  position: relative;
}

.section-heading:after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  left: -15px;
  margin: auto;
  height: 15px;
  background-color: $color-orange;
  transform: skew(-25deg, 0);
}

html.overflow,
body.menu-list_open {
  overflow: hidden;
}

.container {
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 1310px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.section {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 55px 0;

  @media (min-width: 768px) {
    padding: 85px 0;
  }
}

.section__gray-bg {
  background-color: #edf1f3;
}

[data-hidden-text]:after {
  content: attr(data-hidden-text);
}

.Btn {
  display: inline-block;
  text-decoration: none;
  background: $color-orange;
  padding: 13px 30px;
  border-radius: 5px;
  font-size: 18px;
  color: $text-color;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: 0;
  cursor: pointer;
  width: 100%;

  &:hover {
    text-decoration: none !important;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, .6);
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

@supports (padding: max(0px)) {
  .container {
    padding-left: unquote("max(15px, env(safe-area-inset-left))");
    padding-right: unquote("max(15px, env(safe-area-inset-right))");
  }
}

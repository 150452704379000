.Form {
  width: 100%;
  margin: 0;

  input,
  textarea,
  button {
    &:focus,
    &:active {
      outline: none;
    }
  }

  &__label {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 0;
    margin: 0;
    line-height: 1;
    margin-bottom: 10px;
  }

  &__input {
    width: 100%;
    margin: 0;
    border: 1px solid rgba($color-dark, .2);
    padding: 15px 10px;

    &:focus {
      outline: none;
      border-color: $color-orange;
    }

    &::placeholder {
      color: #a4a4a4;
    }

    &:-ms-input-placeholder {
      color: #a4a4a4;
    }

    &::-ms-input-placeholder {
      color: #a4a4a4;
    }

    &.error {
      border-color: #ff4d4d;
    }
  }

  &__button {
    max-width: 100%;

    @media (min-width: 420px) {
      max-width: 280px;
    }
  }

  &__success {
    color: #34b700;
    padding-top: 15px;
    font-size: 14px;
  }

  @media (min-width: 920px) {
    &__label {
      margin-bottom: 20px;
    }

    &__row {
      display: flex;
      justify-content: space-between;

      .Form__label {
        max-width: calc(50% - 10px);
      }
    }
  }
}

.webp .SectionHeader {
  background-image: url("/img/first-screen-bg.webp");
}

html:not(.webp) .SectionHeader {
  background-image: url("/img/first-screen-bg.png");
}

.SectionHeader {
  background-position: 50% bottom;
  color: $color-white;
  padding-top: 80px;
  height: 440px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-bottom: 0;

  .section-heading {
    text-transform: none;
    color: $color-white;
    font-weight: 700;
    margin-top: 20px;
    padding-bottom: 35px;
    margin-bottom: 15px;

    &:after,
    &:before {
      content: "";
      width: 100%;
      max-width: 215px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 20px;
      margin: auto;
      height: 15px;
      background-color: $color-orange;
      transform: skew(25deg, 0);
    }

    &:before {
      left: 20px;
      transform: skew(-25deg, 0);
    }
  }

  .section-descr {
    margin: 0;
    font-weight: 300;
    font-size: 18px;
    font-family: $font-montserrat;
  }

  @media (min-width: 768px) {
    height: 600px;
    background-size: cover;
    background-position: 0 60%;
  }

  @media (min-width: 980px) {
    height: 100vh;
    background-position: 0 70%;

    .section-heading {
      font-size: 72px;
    }

    .section-descr {
      font-size: 28px;
    }
  }
}

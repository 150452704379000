@media (min-width: 1140px) {
  .webp .SectionAboutUs {
    background-image: url("/img/about-us-bg.webp");
  }

  html:not(.webp) .SectionAboutUs {
    background-image: url("/img/about-us-bg.png");
  }
}

.SectionAboutUs {
  @media (min-width: 1140px) {
    background-size: cover;
    background-position: right bottom;
    background-repeat: no-repeat;

    p {
      max-width: 600px;
    }
  }
}

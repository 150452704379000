.SectionContactUs {
  &__location {
    position: relative;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    margin-top: 45px;

    &:before {
      content: "";
      width: 62px;
      height: 90px;
      display: block;
      flex-shrink: 0;
      margin-right: 30px;
      background-image: url("/img/location.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  @media (min-width: 680px) {
    &__info {
      width: 100%;
      max-width: 450px;
      padding-right: 30px;
    }

    &__content {
      display: flex;

      .Form {
        margin-left: auto;
        max-width: 610px;
        width: 100%;
      }
    }
  }
}

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 2;
  padding: 0;
  display: flex;
  background-color: rgba(#182722, .8);
  transition: padding .2s, height .2s;
  will-change: height;

  .Logo {
    margin: auto;
  }

  .Logo__img {
    vertical-align: middle;
    max-height: 49px;
    transition: max-height .2s;
    width: auto;
  }

  &.scroll {
    height: 60px;

    .Logo__img {
      max-height: 35px;
    }
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.Header:not(.scroll) .Header__menu {
  top: 80px;
}

.Header__menu {
  display: flex;
  list-style: none;
  position: fixed;
  top: 60px;
  right: -220px;
  height: 100%;
  max-width: 220px;
  width: 100%;
  z-index: -1;
  background-color: #f8fbff;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, .2);
  transition: right .2s;
  margin: 0;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 15px 0 0;

  &__item {
    padding: 0 15px;
  }

  ul {
    padding: 0;
  }

  li {
    width: 100%;

    span {
      color: $text-color;
      padding: 10px 15px;
      display: inline-block;
    }
  }

  &_open {
    right: 0;
  }
}

@media (min-width: 660px) {
  .Header:not(.scroll) .Header__menu,
  .Header__menu {
    right: auto;
    position: relative;
    top: auto;
    flex-wrap: nowrap;
    background: none;
    box-shadow: none;
    max-width: none;
    z-index: 0;
    margin-left: auto;
    width: auto;
    padding: 0;
    align-items: center;

    li {
      width: auto;
      padding: 0 15px;

      span {
        color: $color-white;
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding: 0 0 5px;

        &:after {
          content: "";
          width: 40px;
          height: 2px;
          background-color: $color-white;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          bottom: 0;
          transform: translateY(15px);
          opacity: 0;
          transition: opacity .2s, transform .2s;
        }
      }

      span:hover,
      &.active span {
        &:after {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}

.Principles {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    margin-top: 30px;
    position: relative;
    max-width: 100%;
    text-align: center;
    padding: 0;

    &:before {
      content: "";
      height: 120px;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center bottom;
      display: block;
      margin-bottom: 15px;
    }

    @for $i from 1 through 4 {
      &:nth-of-type(#{$i}):before {
        background-image: url("/img/pr_#{$i}.svg");
      }
    }
  }

  @media (min-width: 480px) {
    &__item {
      max-width: 50%;
      padding: 0 10px;
    }
  }

  @media (min-width: 768px) {
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;

    &__item {
      max-width: 305px;
      font-weight: 500;
    }
  }
}
